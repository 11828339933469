/** @jsx jsx */
import { graphql, Link } from "gatsby"
import { Container, jsx, Styled, Flex } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { TwitterShareButton } from "react-share"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import Layout from "../components/layout"
import LeftArrow from "../icons/left-arrow"
import SEO from "../components/seo"
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
deckDeckGoHighlightElement()

type ThoughtTemplateProps = {
  data: {
    thought: {
      slug: string
      title: string
      info: string
      date: string
      body: string
      excerpt: string
    }
  }
}

const ThoughtTemplate = ({ data: { thought } }: ThoughtTemplateProps) => {
  const disqusConfig = {
    url: `www.falloutcoder.com/${thought.slug}`,
    identifier: thought.slug,
    title: thought.title,
  }
  return (
    <Layout thought>
      <SEO
        title={`${thought.title} | Falloutcoder`}
        description={thought.excerpt}
        pathname={thought.slug}
        datePublished={thought.date}
        info={thought.info}
        thought
      />
      <Container>
        <Styled.a
          as={Link}
          to="/"
          sx={{
            display: `grid`,
            gridTemplateColumns: `35px 1fr`,
            gridColumnGap: 3,
            backgroundColor: `indigo.1`,
            borderRadius: `lg`,
            boxShadow: `md`,
            p: 3,
            mt: 4,
            mb: [5, 6],
            alignItems: `center`,
            color: `indigo.7`,
            svg: {
              height: `35px`,
              width: `35px`,
              transition: `transform 0.3s ease-in-out`,
            },
            "&:hover": { textDecoration: `none`, color: `indigo.9`, svg: { transform: `translateX(-4px)` } },
            ".primary": {
              color: `indigo.2`,
            },
            ".secondary": {
              color: `indigo.6`,
            },
          }}
        >
          <LeftArrow />
          {` `}
          <div sx={{ fontSize: 1, fontWeight: `medium` }}>Go back to the homepage!</div>
        </Styled.a>
        {/* TODO: Enable Disqus */}
        {/* <CommentCount config={disqusConfig} placeholder={"ahem!"} /> */}
        <div className="thought-speakable">
          <MDXRenderer>{thought.body}</MDXRenderer>
        </div>
        <div
          sx={{
            fontSize: 0,
            borderTopWidth: `1px`,
            borderTopStyle: `solid`,
            borderTopColor: `light`,
            mt: 5,
            pt: 3,
            color: `textMuted`,
            display: `flex`,
            flexWrap: `wrap`,
          }}
        >
          <div sx={{ mr: 4 }}>Published on {thought.date}</div>
          <TwitterShareButton
            url={`https://www.falloutcoder.com${thought.slug}`}
            via="falloutcoder"
            title={thought.title}
            sx={{
              variant: `buttons.transparent`,
              color: `primary`,
              "&:hover, &:focus": {
                boxShadow: `none`,
                cursor: `pointer`,
                textDecoration: `underline`,
                color: `primary`,
              },
              mr: 4,
            }}
          >
            Share on Twitter
          </TwitterShareButton>
          {/* TODO: Enable Disqus */}
          {/* <Disqus config={disqusConfig} /> */}
        </div>
      </Container>
    </Layout>
  )
}

export default ThoughtTemplate

export const query = graphql`
  query($slug: String!) {
    thought(slug: { eq: $slug }) {
      slug
      title
      info
      date(formatString: "MMMM D, YYYY")
      body
      excerpt
    }
  }
`
